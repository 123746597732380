import { template as template_2f72bb453230409f9f668ef482ed2411 } from "@ember/template-compiler";
const FKText = template_2f72bb453230409f9f668ef482ed2411(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
