import { template as template_e126e8e202c0450b967f979e052b7ca1 } from "@ember/template-compiler";
const WelcomeHeader = template_e126e8e202c0450b967f979e052b7ca1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
