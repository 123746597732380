import { template as template_d574db2709e64bb191aa13d8ef8fa5dd } from "@ember/template-compiler";
const FKLabel = template_d574db2709e64bb191aa13d8ef8fa5dd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
