import { template as template_dae93d99822c496b83a96421ed1754e5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_dae93d99822c496b83a96421ed1754e5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
